import React, { useState, useEffect, useRef } from 'react';

// Local images for icons
import linkedinIcon from './assets/linkedin-icon.png'; 
import instagramIcon from './assets/instagram-icon.png'; 
import xIcon from './assets/x-icon.png'; 
import phoneIcon from './assets/phone-icon.png'; 
import customerServiceIcon from './assets/customer-service-icon.png'; 

function ConnectWithUs() {
  const [width, setWidth] = useState(window.innerWidth);
  const sectionRef = useRef(null); // Create a ref for the section

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section ref={sectionRef} className="connectWithUs">
      <div className="row-social">
        <div className="socialContent">
          <button className="services-button">Socials</button>
          <h3>Connect with us</h3>
          <p>Join us in our community or social media to stay updated.</p>
        </div>

        {/* Social Links Section */}
        <div className="socialLinks">
          {/* First Row: LinkedIn and Instagram */}
          <div className="socialRow">
            <SocialCard
              icon={linkedinIcon}  // Use the local LinkedIn icon
              platform="Follow us on LinkedIn"
              url="https://www.linkedin.com/company/aucarga/"
              description="Get Aucarga news, company information, and resources"
              handle="@aucarga"
            />
            <div className="connect-line"></div>
            <SocialCard
              icon={instagramIcon}  
              platform="Follow us on Instagram"
              url="https://www.instagram.com/aucarga/?igsh=MXNmMWY0MGF5OGwwaQ%3D%3D&utm_source=qr"
              description="Get Aucarga news, company information, and resources"
              handle="aucarga"
            />
          </div>

          {/* Second Row: X and Call Us */}
          <div className="socialRow">
            <SocialCard
              icon={xIcon} 
              platform="Follow us on X"
              url="https://x.com/aucarga?s=21&mx=2"
              description="Get Aucarga news, company information, and resources"
              handle="@aucarga"
            />
            <div className="connect-line"></div>
            <SocialCard
              icon={phoneIcon} 
              platform="Call us"
              description="Speak with any of our customer support"
              handle="02940299204"
            />
          </div>

          {/* Contact Us Button */}
          {width >= 600 && (
            <button className="contactButton">
              <span>Contact us</span>
              <img
                src={customerServiceIcon} 
                alt="Customer Service"
                className="buttonIcon"
              />
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

// Reusable Card Component for Social Media Links
const SocialCard = ({ icon, platform, url, description, handle }) => (
  <div className="socialCard">
    <a href={url} target="_blank" rel="noopener noreferrer">
      <img src={icon} alt={`${platform} icon`} className="socialIcon" />
    </a>
    <h4 className="platform">{platform}</h4>
    <p className="description">{description}</p>

    <a href={url} target="_blank" rel="noopener noreferrer" className="handle">
      {handle}
    </a>
  </div>
);

export default ConnectWithUs;

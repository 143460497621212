import React, { useEffect } from 'react'; 
import Header from './Menubar';
import './mobile.css';
import Footer from './BlueFooter';

const PrivacyPolicy = () => {
   
    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);

  return (
<div className="mobile">
      <Header />
      
 
      <div className="mobile-privacy-policy-container">
        <h1>Privacy Policy</h1>
        <p>
          At Aucarga, we value the privacy of our users. This Privacy Policy outlines the information we collect, how we use it, and the steps we take to protect your privacy when you use our website and services.
        </p>

        <h2>1. Information We Collect</h2>
        <h3>1.1 Personal Information</h3>
        <p>When you create an account, book a service, or contact us, we may collect personal information such as:</p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Mailing address</li>
          <li>Vehicle information (make, model, year, VIN, etc.)</li>
        </ul>

        <h3>1.2 Non-Personal Information</h3>
        <p>We may also collect non-personal information that does not identify you directly, including:</p>
        <ul>
          <li>Browser type</li>
          <li>IP address</li>
          <li>Device information</li>
          <li>Usage data (pages visited, time spent on the site, etc.)</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>We use your information to:</p>
        <ul>
          <li>Provide and improve our services</li>
          <li>Process transactions and manage your account</li>
          <li>Send notifications, confirmations, and updates</li>
          <li>Respond to your inquiries and provide customer support</li>
          <li>Personalize your experience and provide tailored recommendations</li>
          <li>Conduct research and analyze website usage to enhance our services</li>
          <li>Comply with legal obligations</li>
        </ul>

        <h2>3. Sharing Your Information</h2>
        <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following circumstances:</p>
        <ul>
          <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our website and delivering our services.</li>
          <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to legal processes.</li>
          <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner.</li>
        </ul>

        <h2>4. Data Security</h2>
        <p>We implement a variety of security measures to protect your personal information. These include encryption, secure servers, and access controls. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

        <h2>5. Your Choices</h2>
        <h3>5.1 Access and Update Information</h3>
        <p>You can access and update your account information by logging into your account on our website.</p>
        <h3>5.2 Opt-Out</h3>
        <p>You can opt-out of receiving promotional emails by following the unsubscribe instructions in the email or by contacting us directly.</p>

        <h2>6. Cookies and Tracking Technologies</h2>
        <p>We use cookies and similar tracking technologies to enhance your experience on our website. Cookies help us understand how you use our site and provide features such as personalized content. You can control cookie settings through your browser preferences.</p>

        <h2>7. Third-Party Links</h2>
        <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites. We encourage you to review the privacy policies of any third-party sites you visit.</p>

        <h2>8. Children's Privacy</h2>
        <p>Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.</p>

        <h2>9. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website and updating the effective date. Your continued use of our services after such changes constitutes your acceptance of the revised policy.</p>

        <h2>10. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <address>
          [Company Name]<br />
          [Company Address]<br />
          [Email Address]<br />
          [Phone Number]
        </address>
      </div>
 
      <Footer />
     
    </div>
  );
};

export default PrivacyPolicy;

import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Menubar from './Menubar';
import Footer from './Footer';
import Footer2 from './BlueFooter';
import './App.css'; 
import ReviewSection from './Reviewsection';
import appStoreImage from './assets/Frame 1000004916.png';
import image1 from './assets/pexels-cottonbro-4489737 2.png';
import LanguageModal from './LanguageModal';
import icon1 from './assets/Frame 1000004931 copy.png';
import icon2 from './assets/Frame 1000004932.png';
import icon3 from './assets/Frame 1000004932 (1).png';
import icon4 from './assets/Frame 1000004931 (2) copy.png';
import icon5 from './assets/Frame 1000004931 (1) copy.png';

function LandingPage() {
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  // Card details: image, text, icon, and hover color
  const cards = [
    {
      id: 1,
      title: "Reach More Customers",
      description: "Increase your visibility and attract more clients by appearing to car owners nearby who are searching for a reliable mechanic. Let the app bring customers to you effortlessly.",
      bgColor: "#FFFFFF",
      image: icon1, 
    },
    {
      id: 2,
      title: "Instant Job Notifications",
      description: "Stay on top of new opportunities with real-time notifications for incoming service requests. With instant alerts, you’re always in the loop and ready to respond quickly to meet customer needs.",
      bgColor: "#FFFFFF",
      image: icon2,
    },
    {
      id: 3,
      title: "Performance Analytics",
      description: "Access insights on customer feedback, service trends, and overall performance to improve your offerings. Use analytics to track growth, identify strengths, and make data-driven decisions for your business.",
      bgColor: "#FFFFFF", 
      image: icon3,
    },
    {
      id: 4,
      title: "Job Bidding",
      description: "Bid on jobs with ease. Submit detailed quotes in just a few clicks, ensuring you spend more time working and less time managing the bidding process.",
      bgColor: "#FFFFFF", 
      image: icon4,
    },
    {
      id: 5,
      title: "Streamlined Scheduling and Appointment",
      description: "Manage your appointments effortlessly. Sync your availability with job requests, and never double-book yourself or miss an opportunity to take on a new job.",
      bgColor: "#FFFFFF", 
      image: icon5,
    },
  ];

  const openLanguageModal = () => {
    setLanguageModalOpen(true);
  };

  const closeLanguageModal = () => {
    setLanguageModalOpen(false);
  };

  return (
    <div className="app">
            {width < 600 ? (
        <Menubar openLanguageModal={openLanguageModal} />
      ) : (
        <Navbar openLanguageModal={openLanguageModal} />
      )}
      
      
      <div className="section-grey-background">
  <div className="section-content">
    {/* Text and Icons Column */}

    <div className="text-and-icons2">
    <div className="garagetop">
    <div className="services-button">
    Garage
          </div>
          </div>
      <h2>Effortlessly expand your customer reach and manage services</h2>
      <p>
      Maximize your business potential by connecting with local car owners in need of immediate service. Streamline your workflow, receive real-time job requests, and build your reputation with customer reviews, all from one convenient platform.
      </p>
      <div className="app-store-icons">
        <img src={appStoreImage} alt="App Store" className="store-icon" />
      </div>
    </div>
    
    {/* Image Column */}
    <div className="image-column">
      <img src={image1} alt="pexel 1" />
    </div>
  </div>
</div>

      


      <div className="cards-section">

           <button className="services-button">
           Features
          </button>

      <div className="card-content-text">
          <h2>How Aucarga simplifies your auto repair needs</h2>
          <p>
          Discover reliable and efficient auto repair solutions tailored for you
          </p>
          </div>
          <div className="card-grid">
      <div className="card-row">
        {cards.slice(0, 3).map((card) => (
          <div
            key={card.id}
            className="car-card"
            style={{ backgroundColor: card.bgColor, width: width < 600 ? "100%" : "378px", height: "342px", borderWidth: 1 }}
          >
            <img src={card.image} alt={card.title} className="car-card-image" />
            <h3 className="car-card-title">{card.title}</h3>
            <p className="car-card-description">{card.description}</p>
          </div>
        ))}
      </div>
      <div className="card-row">
        {cards.slice(3).map((card) => (
          <div
            key={card.id}
            className="car-card"
            style={{ backgroundColor: card.bgColor, width: width < 600 ? "100%" : "578px", height: "342px", borderWidth: 1 }}
          >
            <img src={card.image} alt={card.title} className="car-card-image" />
            <h3 className="car-card-title">{card.title}</h3>
            <p className="car-card-description">{card.description}</p>
          </div>
        ))}
      </div>
    </div>
      </div>

      <div className="review-background">
      <div className="review-section">
      <div className="review-sec-text">
          <h2>Our customers love us</h2>
          <p>
          Hear from some of our amazing customers who are using Aucarga for their auto repair needs
          </p>
          </div>
          <ReviewSection />
</div>
</div>



{width < 600 ? (
        <Footer2 />
      ) : (
        <Footer />
      )}

      <LanguageModal 
        isOpen={isLanguageModalOpen} 
        onRequestClose={closeLanguageModal} 
      />
    </div>
  );
}

export default LandingPage;

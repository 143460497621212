import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Navbar';
import Menubar from './Menubar';
import Footer from './Footer';
import Footer2 from './BlueFooter';
import LanguageModal from './LanguageModal';
import Connect from './connect';

function Contactus() {
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
  const [openSections, setOpenSections] = useState({}); // Track multiple open sections
  const plusCircleIcon = "https://img.icons8.com/?size=100&id=14092&format=png&color=101828";
  const minusCircleIcon = "https://img.icons8.com/?size=100&id=14088&format=png&color=101828";


  const openLanguageModal = () => setLanguageModalOpen(true);
  const closeLanguageModal = () => setLanguageModalOpen(false);

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section], // Toggle the section state
    }));
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="app">
                  {width < 600 ? (
        <Menubar openLanguageModal={openLanguageModal} />
      ) : (
        <Navbar openLanguageModal={openLanguageModal} />
      )}

      <div className="faq-container">
        <h2>Frequestly asked questions</h2>
        <div className="faq-contr">
          <p>Everything you need to know about the product</p>
        </div>

        {[ 
 { id: 1, question: "How does Aucarga work?", answer: "Aucarga connects car owners with nearby mechanics for fast and reliable assistance. Car owners can easily find, review, and book trusted mechanics, while mechanics can expand their customer base and efficiently manage service requests." },
 { id: 2, question: "Is the app available in my area?", answer: "Aucarga is launching in the UK, USA, Luxembourg, Belgium, and the Netherlands. If your location isn’t on this list, rest assured we are working hard to bring Aucarga to your area soon." },
 { id: 3, question: "What kind of services can I book through Aucarga?", answer: "Aucarga supports a wide range of services, including routine maintenance, urgent repairs, diagnostics, and more. Our app ensures you can find the right service for your car needs." },
 { id: 4, question: "How do ratings and reviews work on the app?", answer: "After each service, car owners can leave reviews and ratings for the mechanic or garage. These reviews help others make informed decisions, while mechanics with positive feedback build credibility and attract more customers." },
 { id: 5, question: "Can I cancel or reschedule a booking?", answer: "Absolutely! Aucarga allows you to cancel or reschedule your bookings through the app, provided it’s within the terms of the garage's cancellation policy." },
 { id: 6, question: "Do I need to download the app to use Aucarga?", answer: "Yes, the Aucarga app is the primary platform for accessing our services. It is available for download on both iOS and Android devices." },
 { id: 7, question: "Can I track the progress of my car service?", answer: "Absolutely! Aucarga provides real-time updates on your car service, allowing you to monitor every step from booking to completion." },
 { id: 8, question: "Can I track my service history through the app?", answer: "Yes, Aucarga keeps a detailed log of your service history, including completed services, dates, costs, and feedback for easy reference." },
 { id: 9, question: "Can I use the app to get emergency roadside assistance?", answer: "Yes, Aucarga includes an option for on-demand roadside assistance in areas where it’s available. Help is just a tap away!" },
 { id: 10, question: "What payment methods does Aucarga accept?", answer: "Aucarga supports secure payments through multiple options, including credit/debit cards, mobile wallets, and other popular payment platforms." },
 { id: 11, question: "How do I find mechanic services on the app?", answer: "Car owners can browse a curated list of verified garages and mechanics, filtered by location or service type, to find the perfect match for their needs." },
 { id: 12, question: "How do I list my mechanic services on the app?", answer: "Mechanics and garages can easily list their services by completing a simple onboarding process directly on the app." },
 { id: 13, question: "Can I schedule car maintenance or repairs in advance?", answer: "Yes, Aucarga allows car owners to schedule appointments for maintenance or repairs at their convenience. Simply choose your preferred date and time when booking a mechanic or garage." },
 { id: 14, question: "How does Aucarga ensure the quality of mechanics listed?", answer: "All mechanics and garages listed on Aucarga are verified through a rigorous screening process to ensure they meet our quality standards. Ratings and reviews from other users also help maintain accountability and transparency." },
        ].map((faq) => (
          <div className="faq-section" key={faq.id}>
            <div className="faq-question" onClick={() => toggleSection(faq.id)}>
              <span>{faq.question}</span>
              <img
                src={openSections[faq.id] ? minusCircleIcon : plusCircleIcon}
                alt={openSections[faq.id] ? "Minus Circle" : "Plus Circle"}
                className="plus-arrow"
              />
            </div>
            {openSections[faq.id] && (
  <div className={`faq-answer open`}>
    <p>{faq.answer}</p>
  </div>
)}
          </div>
        ))}
      </div>

      <Connect />
      {width < 600 ? (
        <Footer2 />
      ) : (
        <Footer />
      )}

      <LanguageModal
        isOpen={isLanguageModalOpen}
        onRequestClose={closeLanguageModal}
      />
    </div>
  );
}


export default Contactus;

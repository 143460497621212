import React from 'react';
import Header from './Menubar';
import './mobile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import appStoreImage from './assets/App Store.png';
import googlePlayImage from './assets/Google Play.png';

function App() {
 

  return (
    <div className="mobile">
      <Header />

     
      <div className="mobile-content">
        <h1 className="mobile-heading">Drive with Confidence with Our Professional Car Care Services</h1>
        <h2 className="mobile-subheading">Install Aucarga on your mobile devices for your car repair and maintenance by our reliable and competent autocare services</h2>
        <button className="mobile-owner-download-button">
          <FontAwesomeIcon icon={faDownload} className="download-icon" />
          Download Now
        </button>
        <div className="mobile-owner-boxes-container">
          <div className="mobile-owner-box">
            <div className="mobile-owner-box-content">
              <img 
                src="https://img.icons8.com/?size=100&id=30659&format=png&color=003366" 
                alt="Apple App Store" 
                className="mobile-owner-icon"
              />
              <span className="mobile-owner-box-text">
                <span className="mobile-owner-box-small-text">Mobile phone</span>
                <h3 className="mobile-owner-box-heading">iOS</h3>
                <p className="mobile-owner-box-subheading">Stay ahead with reliable autocare services for your vehicle's peak performance.</p>
                <img 
                  src={appStoreImage} 
                  alt="App Store" 
                  className="mobile-owner-box-button"
                />
              </span>
            </div>
          </div>
          <div className="mobile-owner-box">
            <div className="mobile-owner-box-content">
              <img 
                src="https://img.icons8.com/?size=100&id=2586&format=png&color=003366" 
                alt="Apple App Store" 
                className="mobile-owner-icon"
              />
              <span className="mobile-owner-box-text">
                <span className="mobile-owner-box-small-text">Mobile phone</span>
                <h3 className="mobile-owner-box-heading">Android</h3>
                <p className="mobile-owner-box-subheading">Stay ahead with reliable autocare services for your vehicle's peak performance.</p>
                <img 
                  src={googlePlayImage} 
                  alt="App Store" 
                  className="mobile-owner-box-button"
                />
              </span>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Navbar';
import Menubar from './Menubar';
import Footer from './Footer';
import Footer2 from './BlueFooter';
import LanguageModal from './LanguageModal';
import contactUsImg from './assets/contact-us-img.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'flag-icons/css/flag-icons.min.css';

function Contactus () {
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
  const [phone, setPhone] = useState('');  // State for phone number
  const [message, setMessage] = useState('');
  const maxWords = 250;

  const openLanguageModal = () => {
    setLanguageModalOpen(true);
  };

  const closeLanguageModal = () => {
    setLanguageModalOpen(false);
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMessageChange = (event) => {
    const words = event.target.value.trim().split(/\s+/);
    if (words.length <= maxWords) {
      setMessage(event.target.value);
    }
  };

  const wordCount = message.trim().split(/\s+/).filter(Boolean).length;
  
  return (
    <div className="app">
                 {width < 600 ? (
        <Menubar openLanguageModal={openLanguageModal} />
      ) : (
        <Navbar openLanguageModal={openLanguageModal} />
      )}

      <div className="contact-content">
        <h2>Contact Us</h2>
        <p>
          Our dedicated team is always ready to assist with any questions, concerns, or issues you may have.
        </p>
      </div>
   
      <div className="contact-container">
        <div className="get-in-touch-form">
          <form>
            <div className="form-row">
              <div className="input-container">
                <label>First Name</label>
                <input type="text" placeholder="Enter first name" required />
              </div>
              <div className="input-container">
                <label>Last Name</label>
                <input type="text" placeholder="Enter last name" required />
              </div>
            </div>
            
            <div className="input-container">
              <label>Email</label>
              <input type="email" placeholder="Enter email address" required />
            </div>

          <div className="input-move">   
            <div className="input-container">
              <label>Phone Number</label>
              <div className="phone-input-container">
              <PhoneInput
  country={'nl'}
  enableAreaCodes={true}
  inputStyle={{
    width: '25%',
    height: '45px',
    borderWidth: '1px',
    backgroundColor: 'transparent',
    borderRadius: 0,
    outline: 'none',
    boxShadow: 'none',
    pointerEvents: 'none',
  }}
  containerStyle={{
    border: 'none',
    backgroundColor: 'transparent',
  }}
  buttonStyle={{
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
  }}
/>
</div>
</div>

<input
    type="text"
    className="phone-number-input"
    value={phone}
    placeholder="063 656 9812"
    onChange={(e) => setPhone(e.target.value)} 
  />
  </div>
            <div className="textarea-container">
              <label>Message</label>
              <textarea
                value={message}
                onChange={handleMessageChange}
                placeholder="Enter your message"
                required
              />
              <div className="word-count">{`${wordCount}/${maxWords}`}</div>
            </div>
            
            <button type="submit" className="submit-button">Send Message</button>
          </form>
        </div>

        {width >= 600 && (
        <img
          src={contactUsImg}
          alt="Contact Us"
          className="contact-img"
        />
      )}
      </div>

      {width < 600 ? (
        <Footer2 />
      ) : (
        <Footer />
      )}

      <LanguageModal 
        isOpen={isLanguageModalOpen} 
        onRequestClose={closeLanguageModal} 
      />
    </div>
  );
}

export default Contactus;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import Header from './Menubar';
import './mobile.css';
import backgroundImage from './assets/Frame 1000004027.png';
import quickEffortlessImage from './assets/Quick & Effortless.png';
import realtimeImage from './assets/Real-time.png';
import videoImage from './assets/Video Placholder.png'; 
import serviceImage from './assets/Rectangle 53.png';
import card1img from './assets/Card 1.png';
import FooterSection from './MobileFooter';
import Footer from './BlueFooter';
import component30 from './assets/Component 30.png';
import component31 from './assets/Component 31.png';
import component32 from './assets/Component 32.png';

function App() {
  const [currentFeedbackIndex, setCurrentFeedbackIndex] = useState(0);
  const feedbacks = [
    { 
      image: component30, 
      name: 'James Briston', 
      text: '"I would recommend Aucarga to every car owner that i know! Its been an amazing experience ever since i signed up. Booking an appointment has been seamless and their services are top noth"' 
    },
    { 
      image: component31, 
      name: 'Smith McJohn', 
      text: '"Excellent service! The real-time updates are very helpful and accurate."' 
    },
    { 
      image: component32, 
      name: 'Raymond Venice', 
      text: '"Great app for booking car services. Easy to use and very convenient."' 
    }
  ];

  const realTimeUpdatesRef = useRef(null);

  const handleTouchStart = useCallback((e) => {
    const touchStartX = e.touches[0].clientX;
    const handleTouchEnd = (e) => {
      const touchEndX = e.changedTouches[0].clientX;
      const swipeThreshold = 50;
      if (touchEndX - touchStartX > swipeThreshold) {
        // Swipe right
        setCurrentFeedbackIndex((prevIndex) => (prevIndex === 0 ? feedbacks.length - 1 : prevIndex - 1));
      } else if (touchEndX - touchStartX < -swipeThreshold) {
        // Swipe left
        setCurrentFeedbackIndex((prevIndex) => (prevIndex === feedbacks.length - 1 ? 0 : prevIndex + 1));
      }
      window.removeEventListener('touchend', handleTouchEnd);
    };
    window.addEventListener('touchend', handleTouchEnd);
  }, [feedbacks.length]);

  useEffect(() => {
    // Cleanup touchend event listener on component unmount
    return () => window.removeEventListener('touchstart', handleTouchStart);
  }, [handleTouchStart]);

  const scrollToRealTimeUpdates = () => {
    if (realTimeUpdatesRef.current) {
      realTimeUpdatesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="mobile" onTouchStart={handleTouchStart}>
      <Header />

      <section className="mobile-content">
        <h1 className="main-mobile-heading">Take control of your car's care with Aucarga</h1>
          <h2 className="sub-mobile-heading">book, track, and manage maintenance effortlessly.</h2>
          <div className="cta-mobile-buttons">
          <button className="cta-button downloadnow-mobile-button">Download App</button>
          <button className="cta-button learn-more-button" onClick={scrollToRealTimeUpdates}>Learn More</button>
          </div>
          <div className="mobile-statistics">
            <div className="mobile-stat">
              <h3>18k+</h3>
              <p>Partners</p>
            </div>
            <div className="mobile-stat">
              <h3>1.2k+</h3>
              <p>Garages</p>
            </div>
            <div className="mobile-stat">
              <h3>18k+</h3>
              <p>Car Owners</p>
            </div>
          </div>
          </section>


          <div class="mobile-image-container">
    <img src={backgroundImage} alt="mobile-background" />
    </div>

    <div class="mobile-image-content" ref={realTimeUpdatesRef}>
    <img src={quickEffortlessImage} alt="cards-mob" />
    </div>

    <div className="mobile-text-content">
          <h3>Seamlessly maintain your car with real-time updates</h3>
          <p>Relieve yourself of finding a reliable and trusted garage to help you manage and service your car.</p>
          <button className="mobile-download-black">
            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
              <img 
                src="https://img.icons8.com/?size=100&id=101403&format=png&color=FFFFFF" 
                alt="Apple App Store" 
                className="icon"
              />
            </a>
            <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
              <img 
                src="https://img.icons8.com/?size=100&id=100006&format=png&color=FFFFFF" 
                alt="Google Play Store" 
                className="icon"
              />
            </a>
            Download App
          </button>
        </div>

    <div class="mobile-image-content" >
    <img src={realtimeImage} alt="cards-mob" />
    </div>

    <div className="mobile-text-content">
          <h3>Real time updates at your finger tips from start to finish</h3>
          <p>With real-time tracking, you can stay informed on the repair process. Track the progress of your vehicle's maintenance.</p>
          <button className="mobile-download-black">
            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
              <img 
                src="https://img.icons8.com/?size=100&id=101403&format=png&color=FFFFFF" 
                alt="Apple App Store" 
                className="icon"
              />
            </a>
            <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
              <img 
                src="https://img.icons8.com/?size=100&id=100006&format=png&color=FFFFFF" 
                alt="Google Play Store" 
                className="icon"
              />
            </a>
            Download App
          </button>
        </div>

    <div class="mobile-image-content">
    <img src={videoImage} alt="cards-mob" />
    </div>


          <div className="mobile-text-top">
            <h2>How it works?</h2>
            <p>Learn how to use Aucarga as a car owner for easy maintenance of your car.</p>
            <div className="mobile-content-container">
    <div className="mobile-number-line">
      <span className="mobile-number">01</span>
      <div className="mobile-line"></div>
    </div>
    <div className="mobile-text-column">
      <h2>Effortless Booking</h2>
      <p>Choose a time that fits your schedule without any hassle</p>
    </div>
  </div>
  <div className="mobile-content-container">
    <div className="mobile-number-line">
      <span className="mobile-number">02</span>
      <div className="mobile-line"></div>
    </div>
    <div className="mobile-text-column">
      <h2>Exceptional Service</h2>
      <p>We provide you with a list of garages closest to you for easy accessibility</p>
    </div>
  </div>
  <div className="mobile-content-container">
    <div className="mobile-number-line">
      <span className="mobile-number">03</span>
      <div className="mobile-line"></div>
    </div>
    <div className="mobile-text-column">
      <h2>Easy comparison</h2>
      <p>Weigh your options and quotes to get the most out of pricing and timing</p>
    </div>
  </div>
            <div className="mobile-overlay-buttons">
              <button className="mobile-download-blackpattern">
                <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                  <img 
                    src="https://img.icons8.com/?size=100&id=101403&format=png&color=FFFFFF" 
                    alt="Apple App Store" 
                    className="icon"
                  />
                </a>
                <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
                  <img 
                    src="https://img.icons8.com/?size=100&id=100006&format=png&color=FFFFFF" 
                    alt="Google Play Store" 
                    className="icon"
                  />
                </a>
                Download App
              </button>
              <button className="mobile-learn-more-pattern">
                Learn More
                <img 
                  src="https://img.icons8.com/?size=100&id=85504&format=png&color=000000" 
                  alt="Right Arrow" 
                  className="mobile-right-arrow"
                />
              </button>
            </div>
          </div>

          <div className="mobile-text-top">
            <h2>Our Services</h2>
            </div>

            <div class="mobile-image-container">
    <img src={card1img} alt="Card 1" />
  <div class="mobile-overlay">
    <h2>What We Do</h2>
    <p>We sell convenience, transparency and peace of mind ensuring that our users can easily manage their vehicle needs, virtually through our app and physically while focusing on what truly matters to them.</p>
    <img src={serviceImage} alt="Service" />
  </div>
</div>

<div className="mobile-boxes-container">
<div className="mobile-black-box">
      <h3 className="mobile-black-box-heading">Online Bookings for Maintenance and repair</h3>
      <p className="mobile-black-box-subheading">Allow customers to schedule appointments for various types of vehicle maintenance and services.</p>
</div>
<div className="mobile-black-box">
      <h3 className="mobile-black-box-heading">Service and Repair History Tracking</h3>
      <p className="mobile-black-box-subheading">Maintain a digital record of all services and repairs performed on your vehicle.</p>
</div>
<div className="mobile-black-box">
      <h3 className="mobile-black-box-heading">Diagnostic Tools and Troubleshooting Guides</h3>
      <p className="mobile-black-box-subheading">Offer diagnostic tools to help customers identify potential issues with their vehicles based on symptoms or error codes.</p>
</div>
<div className="mobile-black-box">
      <h3 className="mobile-black-box-heading">Connecting you with reputable repair shops</h3>
      <p className="mobile-black-box-subheading">Connect with garages on the fly within your set radius.</p>
</div>
</div>

<FooterSection />

<div className="feedback-top-row">
<div className="mobile-text-top">
            <h2>Feedbacks</h2>
            </div>
            <div className="feedback-all">
            <h2>View All</h2>
            </div>
            </div>

            <div className="mobile-feedbacks-section">
            
  <div className="mobile-feedback-content">
    <img src={feedbacks[currentFeedbackIndex].image} alt="Feedback" className="mobile-feedback-image" />
    <div className="mobile-feedback-text-container">
      <p className="mobile-feedback-text">{feedbacks[currentFeedbackIndex].text}</p>
      <span className="mobile-feedback-name">- {feedbacks[currentFeedbackIndex].name}</span>
    </div>
  </div>
</div>

<div className="feedback-indicators">
          {feedbacks.map((_, index) => (
            <div 
              key={index} 
              className={`feedback-indicator ${currentFeedbackIndex === index ? 'active' : ''}`} 
            />
          ))}
        </div>
        
<Footer />



    </div>
  );
}

export default App;

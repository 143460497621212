import React, { useEffect } from 'react';
import Header from './Menubar';
import './mobile.css';
import Footer from './BlueFooter';
import AboutUsFooter from './MobileAbtFooter';
import BridgingImage from './assets/Rectangle 79.png';
import OurTeam from './MobileTeams';


function Contactus () {

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  
  return (
    <div className="mobile">
      <Header />
        
        <div class="mobile-bridging-section">
    <div class="mobile-bridging-text">
    <span class="mobile-dark-blue">We build bridges between </span>
    <span class="mobile-light-blue">Car Owners and Garage Owners</span>
</div>
        <p class="mobile-bradditional-text">To create an environment where car owners feel confident entrusting their vehicle maintenance to accredited garages.</p>
    </div>



    <div className="mobile-full-page-section">
  <img src={BridgingImage} alt="Bridging" />
</div>



<div className="mobile-about-white-background-section">
<div className="mobile-core-values-container">
    <h1 className="mobile-core-values-heading">Our Core Values</h1>
    <h2 className="mobile-core-values-subheading">Our company's values are the guiding principle that shape our culture and reflect our commitment to excellence.</h2>
    </div>

    <section className="mobile-about-white-boxes-section">
  <div className="mobile-about-box">
    <h2 className="mobile-about-box-heading">Customer-Centricity</h2>
    <h3 className="mobile-about-box-subheading">Prioritizing the needs and satisfaction of both car owners and garage partners in every decision and action.</h3>
  </div>
  <div className="mobile-about-box">
    <h2 className="mobile-about-box-heading">Integrity and Transparency</h2>
    <h3 className="mobile-about-box-subheading">Building trust through honest communication, fair pricing, and clear service process.</h3>
  </div>
  
  <div className="mobile-about-box">
    <h2 className="mobile-about-box-heading">Innovation</h2>
    <h3 className="mobile-about-box-subheading">Continuously improving and adapting the platform to meet evolving needs through technology and creativity.</h3>
  </div>
  <div className="mobile-about-box">
    <h2 className="mobile-about-box-heading">Reliability and Quality</h2>
    <h3 className="mobile-about-box-subheading">Delivering dependable, high quality services that users and garage can count on.</h3>
  </div>
  </section>
<div className="mobile-core-values-container">
    <h1 className="mobile-core-values-heading">Meet our amazing team</h1>
    </div>


    <OurTeam />





    <div className="mobile-join-team-section">
  <div className="mobile-join-team-row">
    <h1 className="mobile-join-team-heading">Join Our Team</h1>
    <div className="mobile-join-team-subheading-row">
    <h2 className="mobile-join-team-subheading">We believe that outstanding products are the result of outstanding people. That’s why we hire professionals who reflect our values as well as our goals.</h2>
    <h3 className="mobile-join-team-subheading-bottom">See open positions</h3>
  </div>
  </div>
</div>


    </div>
    




    <AboutUsFooter />
      <Footer />

     
  
    </div>
  );
}

export default Contactus;

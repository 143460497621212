import React, { useState } from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import logoImage from './assets/Logo3.png';
import { NavLink } from 'react-router-dom';
import Nav1 from './assets/Frame 1000003999.png';
import Nav2 from './assets/Frame 1000003999 (1).png';
import Nav3 from './assets/Frame 1000003999 (2).png';
import Nav4 from './assets/Frame 1000003999 (3).png';
import Nav5 from './assets/Frame 1000003999 (4).png';
import appStoreImage from './assets/Frame 1000004916.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavigation = (path) => {
    toggleMenu();
    navigate(path);
  };

  const handleDropdownToggle = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const handleLogoClick = () => {
    navigate('/home');
  };

  return (
    <header className="header">
      <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <img src={logoImage} alt="Logo" className="mobile-logo-image" />
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        &#9776;
      </div>
      {menuOpen && (
        <div className="modal-overlay" onClick={toggleMenu}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={logoImage} alt="Logo" className="mobile-logo-cursor" />
            <button className="close-button" onClick={toggleMenu}>
              ✕
            </button>
            <hr className="mobile-footer-divider" />
            <ul className="menu">
              <li className="menu-item" onClick={() => handleNavigation('/home')}>
                Home
              </li>

              <li className="menu-item" onClick={() => handleDropdownToggle('products')}>
                Products
                <img
                  src={
                    activeDropdown === 'products'
                      ? "https://img.icons8.com/?size=100&id=39942&format=png&color=667085"
                      : "https://img.icons8.com/?size=100&id=39942&format=png&color=667085"
                  }
                  alt="Arrow"
                  className={`download-arrow-icon ${activeDropdown === 'products' ? 'rotate' : ''}`}
                />
                {/* Animate the dropdown */}
                <div className={`mobile-popup ${activeDropdown === 'products' ? 'show' : ''}`}>
                  <div className="mobile-popup-column">
                    <NavLink to="/car-owner" className="popup-item">
                      <img src={Nav1} alt="Car Owner" />
                      <div>
                        <h4>Car Owner</h4>
                        <p>Connect with trusted professionals for your car maintenance and repairs, whenever you need them.</p>
                      </div>
                    </NavLink>
                    <NavLink to="/garage-owner" className="popup-item">
                      <img src={Nav2} alt="Garage Owner" />
                      <div>
                        <h4>Garage Owner</h4>
                        <p>Grow your business with real-time job requests and reviews from car owners.</p>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </li>

              <li className="menu-item" onClick={() => handleDropdownToggle('resources')}>
                Resources
                <img
                  src={
                    activeDropdown === 'resources'
                      ? "https://img.icons8.com/?size=100&id=39942&format=png&color=667085"
                      : "https://img.icons8.com/?size=100&id=39942&format=png&color=667085"
                  }
                  alt="Arrow"
                  className={`download-arrow-icon ${activeDropdown === 'resources' ? 'rotate' : ''}`}
                />
                {/* Animate the dropdown */}
                <div className={`mobile-popup ${activeDropdown === 'resources' ? 'show' : ''}`}>
                  <div className="mobile-popup-column">
                    <NavLink to="https://blog.aucarga.com/" className="popup-item">
                      <img src={Nav3} alt="Blogs" />
                      <div>
                        <h4>Blogs</h4>
                        <p>Stay informed about all the latest updates on Aucarga.</p>
                      </div>
                    </NavLink>
                    <NavLink to="/faqs" className="popup-item">
                      <img src={Nav4} alt="Faqs" />
                      <div>
                        <h4>FAQs</h4>
                        <p>Find quick answers to common questions about our products, services, and policies.</p>
                      </div>
                    </NavLink>
                    <NavLink to="/career" className="popup-item">
                      <img src={Nav5} alt="Careers" />
                      <div>
                        <h4>Careers</h4>
                        <p>Join the team today and be part of something great.</p>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </li>

              <li className="menu-item" onClick={() => handleNavigation('/contact-us')}>
                Contact Us
              </li>

              {/* This section will be shown only when neither "Products" nor "Resources" are selected */}
              {activeDropdown === null && (
                <div className={`mobile-bottom ${activeDropdown === null ? 'show' : ''}`}>
                  <hr className="mobile-footer-divider" />
                  <div className="mobile-pop-app-store">
                    <h2>Get the app</h2>
                    <div className="mobile-popup-app-store-icons">
                      <img src={appStoreImage} alt="App Store" className="store-icon" />
                    </div>
                  </div>
                  <a href="https://signup.aucarga.com" target="_blank" rel="noopener noreferrer">
                    <button className="demo-button-mobile">Try Demo</button>
                  </a>
                </div>
              )}
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;

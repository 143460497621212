import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaQuoteLeft } from 'react-icons/fa';
import image1 from './assets/Component 30-1.png';
import './App.css'; 

const reviews = [
    {
        name: 'John Doe',
        image: image1, 
        position: 'Car Owner',
        review: 'This app is quick, found a top-rated repair shop with ease. Highly recommended for hassle-free repairs.',
    },
    {
        name: 'Anya Pro',
        image: image1,
        position: 'Car Enthusiast',
        review: 'Love the app! It helps with managing customer requests efficiently. A game-changer for car groups!',
    },
    {
        name: 'Albert Johnson',
        image: image1,
        position: 'Car Owner',
        review: 'Super helpful app! Found a great repair shop nearby with all the info I needed. Very convenient.',
    },
    {
        name: 'QuickFix Garage',
        image: image1,
        position: 'Garage Owner',
        review: 'This app is a lifesaver! It helps connect requests from customers directly to our garage.',
    },
];

const ReviewSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="review-section">
            <Slider {...settings}>
                {reviews.map((review, index) => (
                    <div key={index} className="review-card">
                        <FaQuoteLeft className="quote-icon" />
                        <p className="review-text">{review.review}</p>
                        <div className="reviewer-info">
                            <img src={review.image} alt={review.name} className="reviewer-image" />
                            <div>
                                <h4 className="reviewer-name">{review.name}</h4>
                                <p className="reviewer-position">{review.position}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ReviewSection;

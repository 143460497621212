import React, { useState } from 'react';
import './App.css';
import Navbar from './Navbar';
import patternImage from './assets/Pattern.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import LanguageModal from './LanguageModal';
import appStoreImage from './assets/App Store.png';
import googlePlayImage from './assets/Google Play.png';
import { useTranslation } from 'react-i18next';

function CarOwner() {
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
  const { t } = useTranslation();

  const openLanguageModal = () => {
    setLanguageModalOpen(true);
  };

  const closeLanguageModal = () => {
    setLanguageModalOpen(false);
  };

  return (
    <div className="app">
    <Navbar openLanguageModal={openLanguageModal} />

    <div 
      className="car-owner" 
      style={{ backgroundImage: `url(${patternImage})` }}
    >
      <div className="content">
        <h1 className="heading">Streamline Car Repairs, Manage Bookings and Grow Your Business with Ease</h1>
        <h2 className="subheading">{t('Owner.description')}</h2>
        <button className="owner-download-button">
          <FontAwesomeIcon icon={faDownload} className="download-icon" />
          {t('carCare.cta')}
        </button>
        <div className="owner-boxes-container">
          <div className="owner-box">
            <div className="owner-box-content">
              <img 
                src="https://img.icons8.com/?size=100&id=30659&format=png&color=003366" 
                alt="Apple App Store" 
                className="owner-icon"
              />
              <span className="owner-box-text">
                <span className="owner-box-small-text">{t('Ownerlabels.mobilePhone')}</span>
                <h3 className="owner-box-heading">iOS</h3>
                <p className="owner-box-subheading">{t('Ownerlabels.autocareServices')}</p>
                <img 
                  src={appStoreImage} 
                  alt="App Store" 
                  className="owner-box-button"
                />
              </span>
            </div>
          </div>
          <div className="owner-box">
            <div className="owner-box-content">
              <img 
                src="https://img.icons8.com/?size=100&id=2586&format=png&color=003366" 
                alt="Apple App Store" 
                className="owner-icon"
              />
              <span className="owner-box-text">
                <span className="owner-box-small-text">{t('Ownerlabels.mobilePhone')}</span>
                <h3 className="owner-box-heading">Android</h3>
                <p className="owner-box-subheading">{t('Ownerlabels.autocareServices')}</p>
                <img 
                  src={googlePlayImage} 
                  alt="App Store" 
                  className="owner-box-button"
                />
              </span>
            </div>
          </div>
        </div>
      </div>


      <LanguageModal 
        isOpen={isLanguageModalOpen} 
        onRequestClose={closeLanguageModal} 
      />
    </div>
    </div>
  );
}

export default CarOwner;

import React from 'react';
import './mobile.css';

function Footer() {
  return (
    <div className="mobile-blue-footer-container" style={{ backgroundColor: '#D1E7FD' }}>
      <div className="mobile-blue-footer-content">

          <h2 className="mobile-blue-footer-heading">Have a question?</h2>
          <h2 className="mobile-blue-footer-heading">We will be delighted to assist you</h2>
          <p className="mobile-blue-footer-subheading">Ask about Aucarga for car owners or garage partners, services, payments, booking and recommendations, or anything else. Our highly skilled reps are always ready to be of help.</p>
          <hr className="blue-footer-line" />
          <div className="mobile-blue-contact-row">
            <button className="mobile-blue-contact-button">Contact Us</button>
            <p className="mobile-blue-contact-number">or Call </p>
            <p className="mobile-blue-contact-line">+49 91512019038</p>
          </div>

      </div>
    </div>
  );
}

export default Footer;

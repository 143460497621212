import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import LandingPage from './Landingpage';
import CarOwner from './CarOwner';
import VehicleOwner from './CarOwner';
import VehicleDownload from './GarageDownload';
import Garage from './Garage';
import Mobile from './Mobile';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
import ContactUs from './ContactUs';
import AboutUs from './AboutUs';
import MobileCarOwner from './MobilecarOwner';
import MobilePolicy from './MobilePolicy';
import TermsofService from './MobileTerms';
import MobileAboutUs from './MobileAbout';
import MobileDownload from './MobileDownloadGarage';
import FAQs from './FAQs';
import Career from './Career';
import './i18n';

function App() {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
  return (
    <Router>
    {width < 600 ? (
      // Mobile routing
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/car-owner" element={<VehicleOwner />} />
        <Route path="/vehicle-download" element={<MobileCarOwner />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/privacy-policy" element={<MobilePolicy />} />
            <Route path="/terms-of-services" element={<TermsofService />} />
            <Route path="/garage-owner" element={<Garage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/aboutus" element={<MobileAboutUs />} />
            <Route path="/career" element={<Career />} />
            <Route path="/garage-download" element={<MobileDownload />} />
      </Routes>
    ) : (
      // Desktop routing
      <>
        <Navbar />
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<LandingPage />} />
            <Route path="/vehicle-owner-download" element={<CarOwner />} />
            <Route path="/garage-owner-download" element={<VehicleDownload />} />
            <Route path="/car-owner" element={<VehicleOwner />} />
            <Route path="/garage-owner" element={<Garage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/home-page" element={<Mobile />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/career" element={<Career />} />
          </Routes>
        </>
      )}
    </Router>
  );
}

export default App;


import React from 'react';
import './mobile.css';
import { Link } from 'react-router-dom';
import logoImage from './assets/Frame 1.png';

function Footer() {

  return (
    <footer className="mobile-closing-footer">
      <div class="mobile-closing-footer-container">


</div>
      <div className="mobile-closing-footer-footer-content">
        <div className="mobile-closing-footer-footer-sections">
          <div className="mobile-closing-footer-footer-header">
          <div className="mobile-closing-footer-column">
  <div className="mobile-closing-footer-get-in-touch">
    <img 
      src={logoImage} 
      alt="Logo" 
      className="mobile-logo-footer"
    />
  </div>

  
</div>



          </div>
          <div className="mobile-closing-footer-column">
          <div className="mobile-closing-footer-absolute">
          <div className="mobile-closing-footer-section">
            <h3>Products</h3>
            <Link to="/car-owner">
            <p>Car owner</p>
            </Link>
            <Link to="/garage-owner">
            <p>Garage owner</p>
            </Link>
            
          </div>
          <div className="mobile-closing-footer-section2">
            <h3>Company</h3>
            <Link to="/contact-us">
            <p>Contact Us</p>
            </Link>
            <Link to="/privacy">
            <p>Privacy policy</p>
            </Link>
            <Link to="/terms">
            <p>Terms of Service</p>
            </Link>
          </div>
          </div>

          <div className="mobile-closing-footer-absolute">
          <div className="mobile-closing-footer-section">
            <h3>Resouces</h3>
            <Link to="/faqs">
            <p>FAQs</p>
            </Link>
            <Link to="/career">
            <p>Careers     </p>
            </Link>
            <a href="https://blog.aucarga.com/" target="_blank" rel="noopener noreferrer">
            <p>Blogs</p>
            </a>
            
          </div>

          <div className="mobile-closing-footer-section2b">
            <h3>Socials</h3>
            <a href="https://www.linkedin.com/company/aucarga/" target="_blank" rel="noopener noreferrer">
            <p>Linkedln</p>
            </a>
            <a href="https://www.instagram.com/aucarga/?igsh=MXNmMWY0MGF5OGwwaQ%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
            <p>Instagram</p>
            </a>
            <a href="https://x.com/aucarga?s=21&mx=2" target="_blank" rel="noopener noreferrer">
            <p>X</p>
            </a>
          </div>

         </div>
        </div>
       
        </div>

      </div>
      <hr className="mobile-closing-footer-divider" />
      <div className="mobile-copyright">
          <h3>© 2024 Aucarga.</h3>
          </div>
    </footer>
  );
}

export default Footer;

import React from 'react';
import './Teams.css';
import Image1 from './assets/adrien profile pic.jpg';
import Image2 from './assets/florin.JPG';

function OurTeam() {
  return (
    <div className="mobile-display-container">
    <div className="mobile-team-container">
      <div className="mobile-team-image-container">
        <img src={Image1} alt="Team Member 1" />
        <div className="team-info">
        <h4>Adrien Logean</h4>
          <p>Chief Executive Officer</p>
        </div>
      </div>
      <div className="mobile-team-image-container">
        <img src={Image2} alt="Team Member 2" />
        <div className="team-info">
        <h4>Florin</h4>
          <p>Managing Director</p>
        </div>
      </div>
      
      </div>









    </div>
  );
}

export default OurTeam;

import React, { useState, useEffect } from 'react'; 
import './App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import LanguageModal from './LanguageModal';
import { useTranslation } from 'react-i18next';
import './i18n'; // Ensure this import is included to initialize i18next

const PrivacyPolicy = () => {
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
  const { t } = useTranslation();

  const openLanguageModal = () => {
    setLanguageModalOpen(true);
  };

  const closeLanguageModal = () => {
    setLanguageModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  return (
    <div className="app">
      <Navbar openLanguageModal={openLanguageModal} />
      <div className="privacy-policy-container">
        <h1>{t('privacyPolicy.title')}</h1>
        <p>{t('privacyPolicy.intro')}</p>

        <h2>{t('privacyPolicy.section1.title')}</h2>
        <h3>{t('privacyPolicy.section1.personalInfo.title')}</h3>
        <p>{t('privacyPolicy.section1.personalInfo.description')}</p>
        <ul>
          {t('privacyPolicy.section1.personalInfo.list', { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <h3>{t('privacyPolicy.section1.nonPersonalInfo.title')}</h3>
        <p>{t('privacyPolicy.section1.nonPersonalInfo.description')}</p>
        <ul>
          {t('privacyPolicy.section1.nonPersonalInfo.list', { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <h2>{t('privacyPolicy.section2.title')}</h2>
        <p>{t('privacyPolicy.section2.description')}</p>
        <ul>
          {t('privacyPolicy.section2.list', { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <h2>{t('privacyPolicy.section3.title')}</h2>
        <p>{t('privacyPolicy.section3.description')}</p>
        <ul>
          {t('privacyPolicy.section3.list', { returnObjects: true }).map((item, index) => (
            <li key={index}>
              <strong>{item.split(':')[0]}</strong>{' '}
              {item.split(':')[1]}
            </li>
          ))}
        </ul>

        <h2>{t('privacyPolicy.section4.title')}</h2>
        <p>{t('privacyPolicy.section4.description')}</p>

        <h2>{t('privacyPolicy.section5.title')}</h2>
        <h3>{t('privacyPolicy.section5.accessUpdate.title')}</h3>
        <p>{t('privacyPolicy.section5.accessUpdate.description')}</p>
        <h3>{t('privacyPolicy.section5.optOut.title')}</h3>
        <p>{t('privacyPolicy.section5.optOut.description')}</p>

        <h2>{t('privacyPolicy.section6.title')}</h2>
        <p>{t('privacyPolicy.section6.description')}</p>

        <h2>{t('privacyPolicy.section7.title')}</h2>
        <p>{t('privacyPolicy.section7.description')}</p>

        <h2>{t('privacyPolicy.section8.title')}</h2>
        <p>{t('privacyPolicy.section8.description')}</p>

        <h2>{t('privacyPolicy.section9.title')}</h2>
        <p>{t('privacyPolicy.section9.description')}</p>

        <h2>{t('privacyPolicy.section10.title')}</h2>
        <p>{t('privacyPolicy.section10.description')}</p>
        <address>
          {t('privacyPolicy.section10.address')}
        </address>
      </div>

      <Footer />
      <LanguageModal 
        isOpen={isLanguageModalOpen} 
        onRequestClose={closeLanguageModal} 
      />
    </div>
  );
};

export default PrivacyPolicy;

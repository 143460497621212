import React from 'react';
import './mobile.css'; 
import appStoreImage from './assets/App Store.png';
import googlePlayImage from './assets/Google Play.png';

function Footer() {
  return (
    <div className="mobile-footer-section">
      <div className="mobile-footer-content">
        <p className="mobile-tiny-text">Download Now</p>
        <h2 className="mobile-footer-heading">Seamless car care in just a click,</h2>
        <h2 className="mobile-footer-heading2">Download Now!</h2>
        <div className="mobile-footer-button">
        <div className="mobile-closing-footer-store-icons">
            <img 
              src={appStoreImage} 
              alt="App Store" 
              className="store-icon"
            />
            <img 
              src={googlePlayImage} 
              alt="Google Play" 
              className="store-icon"
            />
          </div>
          </div>
      </div>
    </div>
  );
}

export default Footer;

import React, { useEffect } from 'react'; 
import Header from './Menubar';
import './mobile.css';
import Footer from './BlueFooter';

const TermsAndConditions = () => {
   
    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);

  return (
<div className="mobile">
      <Header />
      
 
      <div className="mobile-privacy-policy-container">
        <h1>Terms and Conditions</h1>

        <p>Welcome to Aucarga. By accessing or using our website, application, and services, you agree to be bound by these Terms and Conditions. Please read them carefully before using our services.</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By clicking or choosing “Accept the terms,” you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use Aucarga or our services.</p>

        <h2>2. Services Provided</h2>
        <p>Aucarga offers a platform for users to book vehicle maintenance and repair services, track service history, order parts, and access other related services. We act as an intermediary between car owners and garage owners.</p>

        <h2>3. User Accounts</h2>
        <h3>3.1 Registration</h3>
        <p>To use certain features on Aucarga, you must create an account. You agree to provide accurate, current, and complete information during the registration process.</p>

        <h3>3.2 Account Security</h3>
        <p>You are responsible for maintaining the confidentiality of your account information and password. You agree to notify us immediately of any unauthorized use of your account.</p>

        <h2>4. User Conduct</h2>
        <p>You agree to use Aucarga and our services only for lawful purposes. You shall not:</p>
        <ul>
          <li>Use Aucarga in any way that violates applicable laws or regulations.</li>
          <li>Post or transmit any content that is unlawful, defamatory, obscene, or otherwise objectionable.</li>
          <li>Interfere with the operation or security of the website.</li>
          <li>Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>
        </ul>

        <h2>5. Service Booking and Payment</h2>
        <h3>5.1 Booking Services</h3>
        <p>When you book a service through Aucarga, you agree to provide accurate information about your vehicle and the required services.</p>

        <h3>5.2 Payments</h3>
        <p>All payments for services must be made through the methods provided on Aucarga. Prices are subject to change, and we reserve the right to modify pricing at any time without prior notice.</p>

        <h2>6. Cancellation and Refunds</h2>
        <h3>6.1 Cancellation</h3>
        <p>You may cancel a booked service within the time frame specified on Aucarga. Cancellation policies and fees may vary depending on the service provider.</p>

        <h3>6.2 Refunds</h3>
        <p>Refunds for cancelled services will be processed in accordance with our refund policy, which is available on the Aucarga app.</p>

        <h2>7. Service Providers</h2>
        <p>We work with third-party service providers to deliver the services offered on Aucarga. While we strive to partner with reputable garage owners, we are not responsible for the actions or omissions of these third parties.</p>

        <h2>8. Intellectual Property</h2>
        <p>All content on Aucarga, including text, graphics, logos, and software, is the property of Aucarga or our licensors and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any content on Aucarga without our prior written consent.</p>

        <h2>9. Limitation of Liability</h2>
        <p>To the fullest extent permitted by law, Aucarga shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our application or services. Our total liability to you for any claim arising out of or relating to these Terms shall not exceed the amount paid by you for the services in question.</p>

        <h2>10. Indemnification</h2>
        <p>You agree to indemnify and hold Aucarga, its affiliates, and their respective officers, directors, employees, and agents harmless from any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or related to your use of our application or services, your violation of these Terms, or your violation of any rights of another.</p>

        <h2>11. Changes to Terms</h2>
        <p>We may update these Terms from time to time. We will notify you of any significant changes by posting the new Terms on our website and updating the effective date. Your continued use of our services after such changes constitutes your acceptance of the revised Terms.</p>

        <h2>12. Governing Law</h2>
        <p>These Terms shall be governed by and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law principles.</p>

        <h2>13. Contact Us</h2>
        <p>If you have any questions or concerns about these Terms and Conditions, please contact us at:</p>
        <address>
          [Company Name]<br />
          [Company Address]<br />
          [Email Address]<br />
          [Phone Number]
        </address>
      </div>
      <Footer />
      
    </div>
  );
};

export default TermsAndConditions;

import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Menubar from './Menubar';
import Footer from './Footer';
import Footer2 from './BlueFooter';
import './App.css'; 
import ReviewSection from './Reviewsection';
import Connect from './connect';
import appStoreImage from './assets/Frame 1000004916.png';
import image1 from './assets/Frame 134.png';
import LanguageModal from './LanguageModal';
import repairImage from './assets/Illustrations=Quick & Effortless Repair Requests.png';
import serviceImage from './assets/Illustrations=Real-time  Service Tracking.png';
import realTimeImage from './assets/Illustrations=Personalized Service Recommendations.png';
import secureImage from './assets/Illustrations=Secure and Convenient Payments.png';
import BenefitImage from './assets/Benefit section.png';
import BenefitImage2 from './assets/Benefit section copy.png';
import GroupImage from './assets/Group 33.png';

function LandingPage() {
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const [hoveredCard, setHoveredCard] = useState(null);

  const handleHover = (cardIndex) => {
    setHoveredCard(cardIndex);
  };

  const handleLeave = () => {
    setHoveredCard(null);
  };

   useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // Card details: image, text, icon, and hover color
  const cards = [
    {
      image: repairImage,
      text: 'Effortless Repair Request',
      hoverText: 'Easily submit and manage repair requests with a few taps, Aucarga puts you in control making vehicle maintenance a breeze.',
      iconUrl: 'https://img.icons8.com/?size=100&id=5Xm3jQHQGDkD&format=png&color=FFFFFF',
      hoverColor: '#003366'
    },
    {
      image: serviceImage,
      text: 'Real-time Service Tracking',
      hoverText: 'Stay informed throughout the repair process with real-time service tracking. Track the progress of your vehicles maintenance easily.',
      iconUrl: 'https://img.icons8.com/?size=100&id=10247&format=png&color=FFFFFF', 
      hoverColor: '#0E803E'
    },
    {
      image: realTimeImage,
      text: 'Personalized Recommendation',
      hoverText: 'Receive personalized service recommendations based on your vehicles make, model, and maintenance history.',
      iconUrl: 'https://img.icons8.com/?size=100&id=12684&format=png&color=FFFFFF', 
      hoverColor: '#532FA8'
    },
    {
      image: secureImage,
      text: 'Secure & Convenient Payments',
      hoverText: 'Easily complete transactions within the app after the service is done, ensuring a seamless and trustworthy payment process for all your vehicle maintenance needs.',
      iconUrl: 'https://img.icons8.com/?size=100&id=86636&format=png&color=FFFFFF',
      hoverColor: '#101828'
    }
  ];

  const openLanguageModal = () => {
    setLanguageModalOpen(true);
  };

  const closeLanguageModal = () => {
    setLanguageModalOpen(false);
  };

  return (
 <div className="app">
      {width < 600 ? (
        <Menubar openLanguageModal={openLanguageModal} />
      ) : (
        <Navbar openLanguageModal={openLanguageModal} />
      )}
      
      <div className="section-grey-background">
  <div className="section-content">
    {/* Text and Icons Column */}
    <div className="text-and-icons">
      <h2>Connecting car owners with trusted auto repair shops</h2>
      <p>
        Find trusted professionals who are ready to help with your car maintenance or issues
        whenever you need it.
      </p>
      <div className="app-store-icons">
        <img src={appStoreImage} alt="App Store" className="store-icon" />
      </div>
    </div>
    
    {/* Image Column */}
    <div className="image-column">
      <img src={image1} alt="pexel 1" />
    </div>
  </div>
</div>

      


      <div className="cards-section">

           <button className="services-button">
            Services
          </button>

      <div className="card-content-text">
          <h2>Perfect solutions to your auto repair needs</h2>
          <p>
          Discover reliable and efficient auto repair solutions tailored for you
          </p>
          </div>
          <div className="cards-container">
  {cards.map((card, index) => (
   <div
   className="cards-white"
   key={index}
   onMouseEnter={() => handleHover(index)}
   onMouseLeave={handleLeave}
 >
   <div
     className="card"
     style={{
       backgroundColor: hoveredCard === index ? card.hoverColor : '#FAFAFA',
       animation: hoveredCard === index ? 'fadeIn 1s ease forwards' : '', 
     }}
   >
        <div className="card-image">
          <img src={card.image} alt="Card" />
        </div>
        <div className="card-text">
          <p>{card.text}</p>
          <img
            src="https://img.icons8.com/?size=100&id=IGZk6vp3nxFm&format=png&color=003366"
            alt="icon"
            className="icon"
          />
        </div>
        <div className={`card-hover ${hoveredCard === index ? 'show' : ''}`}>
          <img src={card.iconUrl} alt="Icon" className="card-icon" />
          <p className="hoverp">{card.text}</p>
          <p className="hoverp2">{card.hoverText}</p>
          <button
            className="learn-more-btn"
            style={{ color: hoveredCard === index ? card.hoverColor : 'black' }}
          >
            Try Demo
          </button>
        </div>
      </div>
    </div>
  ))}
</div>
</div>

      <div className="card-content-text2">
          <h2>Discover a simpler way to locate auto repair services around you</h2>
          </div>

          <div className="mid-img">
      <img
        src={width < 600 ? BenefitImage2 : BenefitImage}
        alt="pexel 1"
      />
    </div>

      <div className="review-background">
      <div className="review-section">
      <div className="review-sec-text">
          <h2>Our customers love us</h2>
          <p>
          Hear from some of our amazing customers who are using Aucarga for their auto repair needs
          </p>
          </div>
          <ReviewSection />
</div>
</div>

<div className="early-access-2">
<div className="early-access-section">
      <div className="early-section-content">
      <div className="early-section-column">
        <h2>Be the First to Try Aucarga!</h2>
        <p>Be one of the first to try the new Aucarga app. Sign up now to get early access.</p>

        <div className="get-access-button">
        <a href="https://signup.aucarga.com" target="_blank" rel="noopener noreferrer">
          <button className="btn-get-access">Try Demo</button>
          </a>
        </div>
</div>
        <div className="image-container">
          <img src={GroupImage} alt="Get Access" className="access"/>
        </div>
      </div>
    </div>
    </div>

    <Connect />

    {width < 600 ? (
        <Footer2 />
      ) : (
        <Footer />
      )}

      <LanguageModal 
        isOpen={isLanguageModalOpen} 
        onRequestClose={closeLanguageModal} 
      />
    </div>
  );
}

export default LandingPage;

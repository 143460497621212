import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Menubar from './Menubar';
import Footer from './Footer';
import Footer2 from './BlueFooter';
import './App.css';  
import ReviewSection from './Reviewsection';
import appStoreImage from './assets/Frame 1000004916.png';
import image1 from './assets/pexels-mart-production-8869193 2.png';
import LanguageModal from './LanguageModal';
import icon1 from './assets/Frame 1000004931.png';
import icon2 from './assets/Frame 1000004931 (1).png';
import icon3 from './assets/Frame 1000004931 (2).png';
import icon4 from './assets/Frame 1000004931 (3).png';
import icon5 from './assets/Frame 1000004931 (4).png';

function LandingPage() {
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Card details: image, text, icon, and hover color
  const cards = [
    {
      id: 1,
      title: "Find Nearby Mechanics Instantly",
      description: "Locate trusted mechanics in your area within minutes, so help is always just a few taps away. Whether you’re stuck on the roadside or need a quick fix, our app connects you with qualified mechanics nearby for fast assistance.",
      bgColor: "#DBE7FA",
      image: icon1, 
    },
    {
      id: 2,
      title: "Transparent Reviews and Ratings",
      description: "Make confident choices with access to verified reviews and ratings from other car owners. See who has the best reputation and find the mechanic who fits your needs for quality, reliability, and service.",
      bgColor: "#DAFAE0",
      image: icon2,
    },
    {
      id: 3,
      title: "Quick, Easy Bookings",
      description: "Get back on the road faster with a hassle-free booking process. With just a single tap, you can secure a mechanic and enjoy the peace of mind that help is on the way.",
      bgColor: "#DCDDF7", 
      image: icon3,
    },
    {
      id: 4,
      title: "Real-Time Availability",
      description: "Know which mechanics are open and ready to help at any moment. Our real-time availability feature saves you time by showing you who’s available right when you need them.",
      bgColor: "#F8E6F4", 
      image: icon4,
    },
    {
      id: 5,
      title: "Service History Tracking",
      description: "Keep track of all your past repairs and maintenance with a detailed service history. Easily access records to stay informed about your car’s needs and maintain a smoother, safer ride.",
      bgColor: "#FCF5DE", 
      image: icon5,
    },
  ];

  const openLanguageModal = () => {
    setLanguageModalOpen(true);
  };

  const closeLanguageModal = () => {
    setLanguageModalOpen(false);
  };

  return (
    <div className="app">
      {width < 600 ? (
        <Menubar openLanguageModal={openLanguageModal} />
      ) : (
        <Navbar openLanguageModal={openLanguageModal} />
      )}
      
      <div className="section-grey-background">
  <div className="section-content">
    {/* Text and Icons Column */}

    <div className="text-and-icons2">
    <div className="garagetop">
    <div className="services-button">
    Car owner
          </div>
          </div>
      <h2>Find trusted mechanics nearby, anytime</h2>
      <p>
      Say goodbye to the hassle of searching for help when your car needs attention. Our app connects you with reliable, highly-rated mechanics nearby, allowing you to book services with ease and get back on the road faster.
      </p>
      <div className="app-store-icons">
        <img src={appStoreImage} alt="App Store" className="store-icon" />
      </div>
    </div>
    
    {/* Image Column */}
    <div className="image-column">
      <img src={image1} alt="pexel 1" />
    </div>
  </div>
</div>

      


      <div className="cards-section">

           <button className="services-button">
           Benefits
          </button>

      <div className="card-content-text">
          <h2>How Aucarga simplifies your auto repair needs</h2>
          <p>
          Discover reliable and efficient auto repair solutions tailored for you
          </p>
          </div>
          <div className="card-grid">
      <div className="card-row">
        {cards.slice(0, 3).map((card) => (
          <div
            key={card.id}
            className="car-card"
            style={{ backgroundColor: card.bgColor, width: width < 600 ? "100%" : "378px", height: "342px" }}
          >
            <img src={card.image} alt={card.title} className="car-card-image" />
            <h3 className="car-card-title">{card.title}</h3>
            <p className="car-card-description">{card.description}</p>
          </div>
        ))}
      </div>
      <div className="card-row">
        {cards.slice(3).map((card) => (
          <div
            key={card.id}
            className="car-card"
            style={{ backgroundColor: card.bgColor, width: width < 600 ? "100%" : "578px", height: "342px" }}
          >
            <img src={card.image} alt={card.title} className="car-card-image" />
            <h3 className="car-card-title">{card.title}</h3>
            <p className="car-card-description">{card.description}</p>
          </div>
        ))}
      </div>
    </div>
      </div>

      <div className="review-background">
      <div className="review-section">
      <div className="review-sec-text">
          <h2>Our customers love us</h2>
          <p>
          Hear from some of our amazing customers who are using Aucarga for their auto repair needs
          </p>
          </div>
          <ReviewSection />
</div>
</div>



{width < 600 ? (
        <Footer2 />
      ) : (
        <Footer />
      )}

      <LanguageModal 
        isOpen={isLanguageModalOpen} 
        onRequestClose={closeLanguageModal} 
      />
    </div>
  );
}

export default LandingPage;

import React from 'react';
import './Footer.css';
import Image1 from './assets/158 (1).png';
import Image2 from './assets/Rectangle 76 (1).png';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="blue-footer-container" style={{ backgroundColor: '#D1E7FD', padding: '40px', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
      <div className="blue-footer-content">

        <div className="blue-footer-left">
          <h2 className="blue-footer-heading">{t('contactus.question')}</h2>
          <p className="blue-footer-subheading">{t('contactus.details')}</p>
          <hr className="blue-footer-line" />
          <div className="blue-contact-row">
            <button className="blue-contact-button">{t('contactus.contactUs')}</button>
            <p className="blue-contact-number">{t('contactus.call')}</p>
            <p className="blue-contact-line">  +49 91512019038</p>
          </div>
        </div>

        <div className="blue-footer-right">
        <img src={Image1} alt="1st Footer" className="blue-footer-image1" />
        <img src={Image2} alt="2nd Footer" className="blue-footer-image" />
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React, { useState } from 'react';
import { NavLink} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logoImage from './assets/Logo (2).png';
import Nav1 from './assets/Frame 1000003999.png';
import Nav2 from './assets/Frame 1000003999 (1).png';
import Nav3 from './assets/Frame 1000003999 (2).png';
import Nav4 from './assets/Frame 1000003999 (3).png';
import Nav5 from './assets/Frame 1000003999 (4).png';
import appStoreImage from './assets/Frame 1000004916.png';
import './App.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Navbar = ({ openLanguageModal }) => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(null); // Tracks hovered link for showing popups

  const handleMouseEnter = (item) => {
    setHovered(item);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  return (
    <nav className="navbar">
      <ul className="nav-links">
        <li className="logo-item">
          <NavLink to="/home">
            <img src={logoImage} alt="Logo" className="logo-image" />
          </NavLink>
        </li>
        <div className="right-line">
          </div>

          <li
  className="popup-container"
  onMouseEnter={() => handleMouseEnter('products')}
>
  <NavLink to="/home" className="grey">
    Products
    <div
      className="arrow"
      onMouseEnter={() => setHovered('products')}
      onMouseLeave={() => setHovered('')} 
      style={{ display: 'inline-block', cursor: 'pointer' }}
    >
      {hovered === 'products' ? ( 
        <FaChevronUp
          className="down-arrow"
          style={{
            color: '#0056b3',
            fontSize: '24px',
            transition: 'color 0.2s ease',
          }}
          alt="Up Arrow"
        />
      ) : (
        <FaChevronDown
          className="down-arrow"
          style={{
            color: '#667085',
            fontSize: '24px',
            transition: 'color 0.2s ease',
          }}
          alt="Down Arrow"
        />
      )}
    </div>
  </NavLink>
  {hovered === 'products' && (
    <div
      className="popup"
      onMouseEnter={() => handleMouseEnter('products')} 
      onMouseLeave={handleMouseLeave} 
    >
      <div className="popup-column">
        <NavLink to="/car-owner" className="popup-item">
          <img src={Nav1} alt="Car Owner" />
          <div>
            <h4>Car Owner</h4>
            <p>Connect with trusted professionals for your car maintenance and repairs.</p>
          </div>
        </NavLink>
        <NavLink to="/garage-owner" className="popup-item">
          <img src={Nav2} alt="Garage Owner" />
          <div>
            <h4>Garage Owner</h4>
            <p>Grow your business with real-time job requests and reviews from car owners.</p>
          </div>
        </NavLink>
      </div>
      <div className="pop-app-store">
        <h2>Available on Apple store and Google play store</h2>
        <p>Click on the links below to download Aucarga mobile application</p>
        <div className="popup-app-store-icons">
          <img src={appStoreImage} alt="App Store" className="store-icon" />
        </div>
      </div>
    </div>
  )}
</li>


        <li
          className="popup-container"
          onMouseEnter={() => handleMouseEnter('resources')}
        >
          <NavLink to="/home" className="grey">
            Resources
            <div
      className="arrow"
      onMouseEnter={() => setHovered('resources')}
      onMouseLeave={() => setHovered('')} 
      style={{ display: 'inline-block', cursor: 'pointer' }}
    >
      {hovered === 'resources' ? ( 
        <FaChevronUp
          className="down-arrow"
          style={{
            color: '#0056b3',
            fontSize: '24px',
            transition: 'color 0.2s ease',
          }}
          alt="Up Arrow"
        />
      ) : (
        <FaChevronDown
          className="down-arrow"
          style={{
            color: '#667085',
            fontSize: '24px',
            transition: 'color 0.2s ease',
          }}
          alt="Down Arrow"
        />
      )}
    </div>
          </NavLink>
          {hovered === 'resources' && (
            <div className="popup2"
            onMouseEnter={() => handleMouseEnter('resources')} 
            onMouseLeave={handleMouseLeave} >
            <div className="popup-column">
            <NavLink to="https://blog.aucarga.com/" className="popup-item">
                <img src={Nav3} alt="Blogs" />
                <div>
                  <h4>Blogs</h4>
                  <p>Stay informed about all the latest updates on Aucarga</p>
                </div>
              </NavLink>
              <NavLink to="/faqs" className="popup-item">
                <img src={Nav4} alt="Faqs" />
                <div>
                  <h4>FAQs</h4>
                  <p>Find quick answers to common question about our products, services and policies</p>
                </div>
              </NavLink>
              <NavLink to="/career" className="popup-item">
                <img src={Nav5} alt="Careers" />
                <div>
                  <h4>Careers</h4>
                  <p>We’re thrilled to have you on board! Join the team today and be part of something great</p>
                </div> 
              </NavLink>
            </div>
            <div className="pop-app-store2">
      <h2>Available on Apple store and Google play store</h2>
      <p>
      Click on the links below to download Aucarga
mobile application
      </p>
      <div className="popup-app-store-icons2">
        <img src={appStoreImage} alt="App Store" className="store-icon" />
      </div>
    </div>
            </div>
          )}
        </li>

        <li>
          <NavLink to="/contact-us" className={({ isActive }) => (isActive ? 'active2' : 'grey')}>
            Contact us
          </NavLink>
        </li>
      </ul>

      <div className="auth-buttons">
      <a href="https://signup.aucarga.com" target="_blank" rel="noopener noreferrer">
  <button className="downloadnow2-button">Try Demo</button>
</a>
        <button className="downloadnow-button">{t('download_app')}</button>
      </div>
    </nav>
  );
};

export default Navbar;

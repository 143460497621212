import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Navbar';
import patternImage from './assets/Pattern.png';
import LanguageModal from './LanguageModal';
import Footer from './Footer';
import AboutUsFooter from './AboutUsFooter';
import BridgingImage from './assets/Frame 1000003983.png';
import OurTeam from './OurTeam';
import { useTranslation } from 'react-i18next';


function Contactus () {
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
  const { t } = useTranslation();

  const openLanguageModal = () => {
    setLanguageModalOpen(true);
  };

  const closeLanguageModal = () => {
    setLanguageModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  
  return (
    <div className="app">
    <Navbar openLanguageModal={openLanguageModal} />

    <div 
      className="contact-back" 
      style={{ backgroundImage: `url(${patternImage})` }}
    >
        
        <div className="about-container">
        <div class="bridging-section">
    <div class="brtext-container">
    <div class="bridging-text">
    <span class="dark-blue">{t('bridges.title')} </span>
    <span class="light-blue">{t('bridges.title2')}</span>
</div>
        <p class="bradditional-text">{t('bridges.description')}</p>
    </div>
    </div>

</div> 
    

</div>
    <div className="full-page-section">
  <img src={BridgingImage} alt="Bridging" />
</div>



<div className="about-white-background-section">
<div className="core-values-container">
    <h1 className="core-values-heading">{t('coreValues.title')}</h1>
    <h2 className="core-values-subheading">{t('coreValues.description')}</h2>
    </div>

    <section className="about-white-boxes-section">
  <div className="about-box">
    <h2 className="about-box-heading">{t('customerCentricity.title')}</h2>
    <h3 className="about-box-subheading">{t('customerCentricity.description')}</h3>
  </div>
  <div className="about-box">
    <h2 className="about-box-heading">{t('integrityTransparency.title')}</h2>
    <h3 className="about-box-subheading">{t('integrityTransparency.description')}</h3>
  </div>
  <div className="about-box">
    <h2 className="about-box-heading">{t('innovation.title')}</h2>
    <h3 className="about-box-subheading">{t('innovation.description')}</h3>
  </div>
  <div className="about-box">
    <h2 className="about-box-heading">{t('reliabilityQuality.title')}</h2>
    <h3 className="about-box-subheading">{t('reliabilityQuality.description')}</h3>
  </div>
</section>
<div className="core-values-container2">
    <h1 className="core-values-heading">{t('team.meetOurTeam')}</h1>
    </div>


    <OurTeam />





    <div className="join-team-section">
  <div className="join-team-row">
    <h1 className="join-team-heading">{t('team.joinOurTeam')}</h1>
    <div className="join-team-subheading-row">
    <h2 className="join-team-subheading">{t('team.belief')}</h2>
    <h3 className="join-team-subheading-bottom">{t('team.openPositions')}</h3>
  </div>
  </div>
</div>


    </div>
    




    <AboutUsFooter />
      <Footer />

      <LanguageModal 
        isOpen={isLanguageModalOpen} 
        onRequestClose={closeLanguageModal} 
      />
  
    </div>
  );
}

export default Contactus;

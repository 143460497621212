import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Navbar';
import Menubar from './Menubar';
import Footer from './Footer';
import Footer2 from './BlueFooter';
import LanguageModal from './LanguageModal';
import { FaLinkedin, FaFacebook } from 'react-icons/fa';
import User1 from './assets/adrien profile pic.jpg';
import User2 from './assets/florin.JPG';
import icon1 from './assets/Frame 1000004931 copy 2.png';
import icon2 from './assets/Frame 1000004932 copy.png';
import icon3 from './assets/Frame 1000004933.png';

function Contactus() {
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openLanguageModal = () => {
    setLanguageModalOpen(true);
  };

  const closeLanguageModal = () => {
    setLanguageModalOpen(false);
  };

  const teamMembers = [
    {
      name: 'Adrien Logean',
      role: 'CEO',
      image: User1,
      linkedin: '#',
      facebook: '#',
    },
    {
      name: 'Florin',
      role: 'Deputy - CEO',
      image: User2,
      linkedin: '#',
      facebook: '#',
    },
  ];

   // Card details: image, text, icon, and hover color
   const cards = [
    {
      id: 1,
      title: "Attractive Compensation Packages",
      description: "We offer attractive compensation packages that are designed to reward talent and recognize hard work, ensuring our employees are fairly compensated for their skills and contributions.",
      bgColor: "#FFFFFF",
      image: icon2, 
    },
    {
      id: 2,
      title: "Career Growth and Development",
      description: "We empower employees with opportunities for career development, helping them enhance their skills and advance professionally, which in turn drives improved performance and job satisfaction",
      bgColor: "#FFFFFF",
      image: icon3,
    },
    {
      id: 3,
      title: "Flexible Work Schedule",
      description: "We offer employees the flexibility of a hybrid work model, giving them the freedom to choose between working from the office or remotely, allowing for a better work-life balance while staying productive and connected.",
      bgColor: "#FFFFFF", 
      image: icon1,
    },
  ];

  return (
    <div className="app">
                  {width < 600 ? (
        <Menubar openLanguageModal={openLanguageModal} />
      ) : (
        <Navbar openLanguageModal={openLanguageModal} />
      )}

      <div className="contact-content">
        <button className="career-button">The team</button>
        <h2>Meet the team behind Aucarga</h2>
        <p>
          We are a group of skilled and dedicated individuals united by a shared
          commitment to a common goal.
        </p>
      </div>

      <div className="team-section">
  {teamMembers.map((member, index) => (
    <div className="team-card" key={index}>
      {/* Image and Info */}
      <div className="team-image-container2">
        <img src={member.image} alt={`${member.name}`} className="team-image" />
        
      </div>
      
      {/* Social Media Icons */}
      <div className="team-details">
        <h3 className="team-name">{member.name}</h3>
        <p className="team-role">{member.role}</p>
        <div className="social-icons">
          <a href={member.facebook} target="_blank" rel="noopener noreferrer">
            <FaFacebook className="social-icon" />
          </a>
          <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="social-icon" />
          </a>
        </div>
      </div>
    </div>
  ))}
</div>


      <div className="review-background">
      <div className="career-cards-section">


<div className="career-card-button">
Benefits
</div>

<div className="career-button-row">
<div className="career-card-content-text">
<h2>Benefits of working with us</h2>
{width < 600 && (
          <p>
            Discover reliable and efficient auto repair solutions tailored for you
          </p>
        )}
</div>
<button className="openpostButton">
        <span>View open positions</span>
      </button>
</div>

<div className="card-grid">
<div className="card-row">
{cards.slice(0, 3).map((card) => (
<div
 key={card.id}
 className="car-card"
 style={{ backgroundColor: card.bgColor, width: width < 600 ? "100%" : "378px", height: "342px", borderWidth: 1 }}
>
 <img src={card.image} alt={card.title} className="car-card-image" />
 <h3 className="car-card-title">{card.title}</h3>
 <p className="car-card-description">{card.description}</p>
</div>
))}
</div>
</div>
</div>
</div>
      
{width < 600 ? (
        <Footer2 />
      ) : (
        <Footer />
      )}

      <LanguageModal isOpen={isLanguageModalOpen} onRequestClose={closeLanguageModal} />
    </div>
  );
}

export default Contactus;

import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import './LanguageModal.css';

const customStyles = {
  overlay: {
    backgroundColor: 'transparent',
  },
  content: {
    top: '10%',
    left: '70%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translateX(-50%)',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#fff',
    border: 'none',
  },
};

const languages = [
  { code: 'en', name: 'english', flag: 'https://img.icons8.com/?size=100&id=t3NE3BsOAQwq&format=png&color=000000' },
  { code: 'fr', name: 'french', flag: 'https://img.icons8.com/?size=100&id=3muzEmi4dpD5&format=png&color=000000' },
  { code: 'es', name: 'spanish', flag: 'https://img.icons8.com/?size=100&id=ly7tzANRt33n&format=png&color=000000' },
  { code: 'de', name: 'german', flag: 'https://img.icons8.com/?size=100&id=hTMPE6ntTofO&format=png&color=000000' },
  { code: 'nl', name: 'dutch', flag: 'https://img.icons8.com/?size=100&id=GRtAYjjO_vFr&format=png&color=000000' },
];

function LanguageModal({ isOpen, onRequestClose }) {
  const { t, i18n } = useTranslation();

  console.log('Current language:', i18n.language); // Debug current language

  const handleLanguageChange = (lang) => {
    console.log('Changing language to:', lang); // Debug language change
    i18n.changeLanguage(lang);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel={t('select_language')}
    >
      <h2>{t('select_language')}</h2>
      <div className="language-options">
        {languages.map(({ code, name, flag }) => (
          <div
            key={code}
            className="language-option"
            onClick={() => handleLanguageChange(code)}
          >
            <img src={flag} alt={name} />
            <span>{t(name)}</span>
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default LanguageModal;

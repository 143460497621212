import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import LanguageModal from './LanguageModal';
import { useTranslation } from 'react-i18next';

const TermsAndConditions = () => {
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
  const { t } = useTranslation();

  const openLanguageModal = () => {
    setLanguageModalOpen(true);
  };

  const closeLanguageModal = () => {
    setLanguageModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  return (
    <div className="app">
      <Navbar openLanguageModal={openLanguageModal} />
      <div className="privacy-policy-container">
        <h1>{t('terms_and_conditions')}</h1>

        <p>{t('welcome_message')}</p>

        <h2>{t('acceptance_of_terms')}</h2>
        <p>{t('accept_terms_message')}</p>

        <h2>{t('services_provided')}</h2>
        <p>{t('services_message')}</p>

        <h2>{t('user_accounts')}</h2>
        <h3>{t('registration')}</h3>
        <p>{t('registration_message')}</p>

        <h3>{t('account_security')}</h3>
        <p>{t('account_security_message')}</p>

        <h2>{t('user_conduct')}</h2>
        <p>{t('user_conduct_message')}</p>
        <ul>
          {t('user_conduct_violations', { returnObjects: true }).map((violation, index) => (
            <li key={index}>{violation}</li>
          ))}
        </ul>

        <h2>{t('service_booking_and_payment')}</h2>
        <h3>{t('booking_services')}</h3>
        <p>{t('booking_services_message')}</p>

        <h3>{t('payments')}</h3>
        <p>{t('payments_message')}</p>

        <h2>{t('cancellation_and_refunds')}</h2>
        <h3>{t('cancellation')}</h3>
        <p>{t('cancellation_message')}</p>

        <h3>{t('refunds')}</h3>
        <p>{t('refunds_message')}</p>

        <h2>{t('service_providers')}</h2>
        <p>{t('service_providers_message')}</p>

        <h2>{t('intellectual_property')}</h2>
        <p>{t('intellectual_property_message')}</p>

        <h2>{t('limitation_of_liability')}</h2>
        <p>{t('limitation_of_liability_message')}</p>

        <h2>{t('indemnification')}</h2>
        <p>{t('indemnification_message')}</p>

        <h2>{t('changes_to_terms')}</h2>
        <p>{t('changes_to_terms_message')}</p>

        <h2>{t('governing_law')}</h2>
        <p>{t('governing_law_message')}</p>

        <h2>{t('contact_us')}</h2>
        <p>{t('contact_us_message')}</p>
        <address>
          {t('company_name')}<br />
          {t('company_address')}<br />
          {t('email_address')}<br />
          {t('phone_number')}
        </address>
      </div>
      <Footer />
      <LanguageModal 
        isOpen={isLanguageModalOpen} 
        onRequestClose={closeLanguageModal} 
      />
    </div>
  );
};

export default TermsAndConditions;
